<template>
<div class="product">
<div  class="product-banner">
      <div class="product-banner-inner">
          <div class="product-banner-inner-left wow animate__animated animate__bounceInLeft">
            CLASSIFICATION
          </div>
        <div class="product-banner-inner-right wow animate__animated animate__bounceInRight">
          Find The Best Titanium You Want At Shen Ji
        </div>
      </div>
</div>
<!--line-->
  <div class="product-line">
    <div class="product-line-inner">
      <router-link to="/">
      <div>
         Home
       </div>
      </router-link>
      <div>/</div>
      <div>Product</div>
    </div>
  </div>

<!--  product-content-->
  <div class="product-content">
      <div class="product-content-top">
        <a  style="display: flex">
            <div :class="['product-content-top-item',{'product-content-top-item-active':catIndex == index}]" v-for="(item,index) in catList" :key="item.num" @click="changeCat(index,item.id)">
              {{ item.name}}
            </div>
        </a>
      </div>
    <div class="product-content-line"></div>
    <div class="product-content-area">
      <div class="home-now-recommended-content-item product-content-area-item  " v-for="item in shop " :key="item.num">
        <router-link target="_blank"
          :to="{
                      path:'ProductD',
                      query:{id:item.id}
                }" >
        <img :src="'http://cdn.fish-cloud.com/'+item.img" alt="">
        <div class="home-now-recommended-content-item-text">
          <div >

          </div>
          <div >
            <router-link
                :to="{
                      path:'ProductD',
                      query:{id:item.id}
                }">
              {{ item.name }}
            </router-link>
          </div>

         <div>

         </div>
        </div>
        </router-link>
      </div>
      <div style="width: 100%">
      <div class="new-fenye">
        <a-pagination v-model="current" :total="fenyeNum" show-less-items  @change="huoqu()"/>
      </div></div>
    </div>
<!--    <div class="fenye-out">-->
<!--      <div class="fenye-prev fenye-icon"> &lt;</div>-->
<!--    <div :class="['feye',{'fenye-cative':index == activeFenye}]" v-for="(item,index) in fenyeNum" :key="item.num" @click="changeActive(index)">-->
<!--      {{item}}-->
<!--    </div>-->
<!--      <div class="fenye-next fenye-icon">-->

<!--        >-->
<!--      </div>-->
<!--    </div>-->

  </div>
<!--product-review-->
  <div class="product-customer-review">

        <div class="home-custom-review-item-right product-custom-review-item-right">
          <div class="home-custom-review-item-right-text-title">
            CUSTOMER REVIEW
          </div>
          <div style="display: flex ;border-top: 1px">
            <div class="home-custom-review-item-right-peo">
              <div class="home-custom-review-item-right-peo-pic">
                <img :src="this.picList[this.picIndex]" alt="">
              </div>


            </div>
            <div class="home-custom-review-item-right-text">

              <splide :options="option1"
                      @splide:moved="onMounted">
                <splide-slide>
                  <div class="home-custom-review-item-right-text-text">
                    Shenji is a A supplier to us. They have a very high stable quality and we appreciate their good service. We can always relay on getting the material just in time.
                  </div>
                  <div class="home-custom-review-item-right-text-text-peo">
                    <span style="color:#258B3B;">Sweden</span>
                  </div>
                </splide-slide>
                <splide-slide>
                  <div class="home-custom-review-item-right-text-text">
                    We are very satisfied with the top quality of Ti Gr.5 and Ti Gr. 2 sheets which we received from your company. We have never got any claim up to now. Also the delivery date and service are very reliable.
                  </div>
                  <div class="home-custom-review-item-right-text-text-peo">
                 <span style="color:#258B3B;"> Germany</span>
                  </div>
                </splide-slide>
                <splide-slide>
                  <div class="home-custom-review-item-right-text-text">
                    Excellent Titanium sheets and plates! Plant really stands behind quality and short production time. Shenji is also capable to manufacture aerospace quality material per AMS standards.
                  </div>
                  <div class="home-custom-review-item-right-text-text-peo">
                 <span style="color:#258B3B;"> USA</span>
                  </div>
                </splide-slide>
                <splide-slide>
                  <div class="home-custom-review-item-right-text-text">
                    We are happy to tell you that your titanium plates are perfect. We'd like to thank you again for your hospitality and help during our visit to your company.
                  </div>
                  <div class="home-custom-review-item-right-text-text-peo">
                 <span style="color:#258B3B;"> Italy</span>
                  </div>
                </splide-slide>
                <splide-slide>
                  <div class="home-custom-review-item-right-text-text">
                    Our goods arrived on time without any delay. Thank you very much for completing such a large order perfectly. Looking forward to our next cooperation!
                  </div>
                  <div class="home-custom-review-item-right-text-text-peo">
                <span style="color:#258B3B;"> Australia</span>
                  </div>
                </splide-slide>
                <splide-slide>
                  <div class="home-custom-review-item-right-text-text">
                    Everything is great. Very happy to work with Tina, who has helped us get everything straight. We will certainly order again next time!

                  </div>
                  <div class="home-custom-review-item-right-text-text-peo">
                 <span style="color:#258B3B;"> Russia</span>
                  </div>
                </splide-slide>

              </splide>
            </div>
          </div>
        </div>


  </div>


<!--  -->
  <div class="home-solution product-solution">
    <p class="home-main-cate-title-common home-solution-title wow animate__animated animate__fadeInDown" STYLE="color:#001659;">
      SOLUTION
    </p>
    <div class="product-solution-content">

      <div class="product-solution-content-item">
          <div class="product-solution-content-item-inner">
              <p class="product-solution-content-item-inner-title">
                Aerospace Industry
              </p>
              <p class="product-solution-content-item-inner-text">
                We provide high-performance specialty metals for critical applications in the aerospace market, including commercial airframe, engine, internal and external systems, military and defense, rotors, space and many other applications.
              </p>
          </div>
      </div>
      <div class="product-solution-content-item product-solution-content-item1">
        <div class="product-solution-content-item-inner">
          <p class="product-solution-content-item-inner-title">
            Power Generation
          </p>
          <p class="product-solution-content-item-inner-text">
            Titanium is utilized in the power generation industry in turbine blade applications. Besides, titanium tubing is used on a large scale in the condenser and auxillary heat exchanger applications in power plants.
          </p>
        </div>
      </div>
      <div class="product-solution-content-item product-solution-content-item2">
        <div class="product-solution-content-item-inner">
          <p class="product-solution-content-item-inner-title">
            Oil And Gas
          </p>
          <p class="product-solution-content-item-inner-text">
            Oil and gas concentrates the key resources and capabilities of the titanium industry in the global oil and gas market.
            By providing titanium and specialty metals with superalloys, we are able to provide high-strength, corrosion-resistant materials for the most challenging oil and gas applications and environments, such as exploration, production and refining.
          </p>
        </div>
      </div>
      <div class="product-solution-content-item product-solution-content-item3">
        <div class="product-solution-content-item-inner">
          <p class="product-solution-content-item-inner-title">
            Medical Treatment
          </p>
          <p class="product-solution-content-item-inner-text">
            As a new type of alloy, medical titanium alloy is widely used in related fields such as limb implants, alternative functional materials, dentistry, and medical equipment. Titanium and titanium alloys have the following advantages: good corrosion resistance, high specific strength, low elastic modulus, fatigue resistance, and good biocompatibility.
          </p>
        </div>
      </div>
    </div>
   </div>
  <Bottom></Bottom>
</div>
</template>

<script>
// import { Swiper, SwiperSlide } from 'swiper/vue';
// import Swiper from 'swiper'
import {Splide,SplideSlide} from  '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import WOW from 'wowjs'
import Bottom from "@/components/Bottom";
export default {
name: "Product",
components:{
  SplideSlide,
  Splide,
  Bottom
},
  data(){
  return{

    catList:[
        // 'All',
        // 'Titanium Plates',
        // 'Titanium Round Bars',
        // 'Titanium Wires'
    ],
    swiperOptions:{
      loop:true,
      slidesPerView:1,
      navigation:{
        prevEl:'.swiper-button-prev',
        nextEl:'.swiper-button-next',
      },

    },
    option1:{
      perPage:1,
      // gap:10,
      type:'loop',
      perMove:1,
      autoplay:true,
      interval:2000,
      width:567
    },
    swiperSlider:[],
    picIndex:0,
    picList:[
      '/img/home-rentou7.png',
      '/img/home-rentou6.png',
      '/img/home-rentou5.png',
      '/img/home-rentou1.png',
      '/img/home-rentou3.png',
      '/img/home-rentou2.png',
    ],
    catIndex:0,
    smm:{
      pageIndex:'1',
      pageSize:'9',
      catid:'',
      keyword:''
    },
    shop:[],
    fenyeNum:0,
    activeFenye:0,
    current:1,
  }
  },
created() {
  this.getdata()
  this.changeCat(0,'')
},

  methods:{
  getdata(){
    this.$axios.get('api/cats').then(res=>{
      console.log(res.data)
      this.catList = res.data

        }
    )
  },
    onMounted() {

      if (this.picIndex<6){
        this.picIndex=this.picIndex+1
      }
      if(this.picIndex==6){
        this.picIndex = 0
      }
// console.log(this.picIndex)
    },
    changeCat(e,f){
      this.catIndex = e
      if(f==248){
        this.smm.catid = ''
      }else {
        this.smm.catid= f
      }
this.smm.pageIndex=1
      this.$axios.get('api/shops',{params:this.smm}).then(res=>{
      this.shop = res.data.datas
        this.fenyeNum = res.data.totalCount

        // console.log(this.fenyeNum);
      })

    },
    changeActiveg(g){
    this.activeFenye = g;
    },
    huoqu(){
    this.smm.pageIndex = this.current
      console.log(this.smm)

      this.$axios.get('api/shops',{params:this.smm}).then(res=>{
        this.shop = res.data.datas
        this.fenyeNum = res.data.totalCount

        // console.log(this.fenyeNum);
      })
    },

  },

mounted() {
  new WOW().init()
}
}
</script>

<style scoped>
@import url("../assets/css/st.css");
a{
  text-decoration: none;
  color: white;
}
</style>
