<template>
  <div  class="news">
    <div  class="product-banner news-banner">
      <div class="product-banner-inner">
        <div class="product-banner-inner-left">
          NEWS
        </div>
        <div class="product-banner-inner-right">
          Our Latest Insights
        </div>
      </div>
    </div>
    <div class="product-line">
      <div class="product-line-inner">
        <div>
          Home
        </div>
        <div>/</div>
        <div>News</div>
      </div>
    </div>


    <!--  NEWS-CONTENT-->
    <div class="news-content">

      <div class="news-content-left">
        <p class="news-content-left-title">
          COMPANY NEWS
        </p>
          <div class="news-de-item">
            <img :src="'http://cdn.fish-cloud.com/'+newD.articleImg" alt="">
            <p class="news-de-item-title">{{ newD.name }}</p>
            <p class="news-de-item-text" v-html=" newD.articleContent">


            </p>
          </div>

      </div>

      <div class="news-content-right">
        <div class="news-content-right-item" >
          <p :class="['news-content-right-item-title',{'news-content-right-item-title-active':newsNum == 1}]" @click="changeNews(1,catInfo[0].id)">
            {{catInfo[0].name}}  >
          </p>

          <div class="news-content-right-item-item" style="margin-top: 49px" v-for="item in newList1" :key="item.num">
            <router-link :to="{
          path:'/about',
          query:{id:item.id}
          }">
              <div style="display: flex">
                <div class="news-content-right-item-item-left" style="width: 100px;height: 100px;overflow: hidden">
                  <img :src="'http://cdn.fish-cloud.com/'+item.articleImg" alt="" height="100px">
                </div>
                <div class="news-content-right-item-item-right">
                  <p class="news-content-right-item-item-right-top">{{ item.releaseDate }}</p>
                  <p class="news-content-right-item-item-right-title">
                    {{
                      item.name
                    }}
                  </p>
                </div>
              </div>
            </router-link>
          </div>

        </div>

        <div class="news-content-right-item" >
          <p :class="['news-content-right-item-title',{'news-content-right-item-title-active':newsNum == 2}]"  @click="changeNews(2,catInfo[1].id)">
            {{catInfo[1].name}} >
          </p>


          <div class="news-content-right-item-item" style="margin-top: 49px" v-for="item in newList2" :key="item.num">
            <router-link :to="{
      path:'/about',
          query:{id:item.id}
          }">
              <div style="display: flex">
                <div class="news-content-right-item-item-left" style="width: 100px;height: 100px;overflow: hidden">
                  <img :src="'http://cdn.fish-cloud.com/'+item.articleImg" alt="" height="100px">
                </div>
                <div class="news-content-right-item-item-right">
                  <p class="news-content-right-item-item-right-top">{{ item.releaseDate }}</p>
                  <p class="news-content-right-item-item-right-title">
                    {{
                      item.name
                    }}
                  </p>
                </div>
              </div>
            </router-link>
          </div>
        </div>


      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Bottom from "@/components/Bottom";
export default {
name: "NewsDe",
  components:{
  Bottom
  },
  data(){
  return{
    newD:{},
    newsInnercanshu:{
      pageIndex: 0,
      pageSize: 3,
      totalCount: 0,
      catId:'',
      datas: [
        {
          id: 0,
          articleUrl: "string",
          articleImg: "string",
          articleContent: "string",
          articleAbstract: "string",
          releaseDate: "2021-02-01T03:46:32.146Z",
          userId: 0,
          name: "string",
          seoTitle: "string",
          seoKeyWords: "string",
          seoDescription: "string",
          someItemsId: [
            0
          ],
          articleAuthor: "string",
          articleForm: "string",
          articleWebUrl: "string",
          browseCount: 0
        }
      ],


    },
    newsNum:{},
    newList:[],
    newList1:[],
    newList2:[],
    catInfo:[],
  }
  },
  created() {
    this.getdata()
  },
  methods:{
    changeNews(e,f){
      this.newsNum = e,
        this.newsInnercanshu.catId = f
      this.$axios.get('/api/news', {params:this.newsInnercanshu}).then(res=>{
        this.newList=res.data.datas

      })

    },
    getdata(){
      this.$axios.get('/api/newsDetail',{params:{id:this.$route.query.id}}).then(res=>{
        this.newD = res.data
        console.log(this.newD)
      })
      this.newsInnercanshu.catId = 308
      this.$axios.get('/api/news', {params:this.newsInnercanshu}).then(res=>{
        this.newList=res.data.datas
        this.newList1 = res.data.datas

      })
      this.newsInnercanshu.catId = 309
      this.$axios.get('/api/news', {params:this.newsInnercanshu}).then(res=>{
        // this.newList=res.data.datas
        this.newList2 = res.data.datas

      })
      this.$axios.get('/api/cats',{params:{catType:1}}).then(res=>{
        this.catInfo = res.data
        console.log(this.catInfo[0].id,'123')

      })
    }
  }
}
</script>

<style scoped>

</style>
