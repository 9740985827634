<template>
<div class="company">
  <div  class="product-banner company-banner">
    <div class="product-banner-inner">
      <div class="product-banner-inner-left wow animate__animated animate__bounceInLeft">
        ABOUT US
      </div>
      <div class="product-banner-inner-right wow animate__animated animate__bounceInRight">
        Best Titanium Supplier Since 2008
      </div>
    </div>
  </div>
  <div class="product-line">
    <div class="product-line-inner">
      <a href="/" style="color: white">
      <div>
        Home
      </div>
      </a>
      <div>/</div>
      <div>Company</div>
    </div>
  </div>
<!--  company--->
  <div class="company-company-intro">
    <p class="home-main-cate-title-common wow animate__animated animate__fadeInDown" >
      COMPANY INTRODUCTION
    </p>
    <p class="company-company-intro-text wow animate__animated animate__fadeInDown" >
      A world-class titanium company. This is what we aim for. Since 2008, we have been specializing in the production and sales of titanium and titanium alloy sheets/plates. We have 4 complete production lines of titanium and titanium alloy sheets and our annual output are 3,000 tons for various grades.
      In our factory of more than 38,000 square meters, we can provide cold-rolled products through veneer rolling with width of 1,250mm and thickness of 0.4mm. Our titanium alloy sheet can meet thickness from 0.5mm, reaching the industry-leading level. Welcome to contact us for more details!
    </p>
    <div class="home-company-intro-video">
      <a >
        <div class="home-company-intro-video-anniu" @click="changeVideo(1)" v-if="this.videocanshu ==0">
          <span></span>

        </div>
      </a>
      <img src="../../public/img/home-com-intro-vid-pic.jpg" alt="" @click="changeVideo(1)" v-if="this.videocanshu == 0">
      <video src="../../public/img/home-video.mp4" controls="controls" width="1293"  autoplay v-if="this.videocanshu == 1"></video>
    </div>
  </div>
<!--  company-shuzi-->
  <div class="company-company-shuzi">
    <div class="company-company-shuzi-num">
        <p class="company-company-shuzi-num-title">12+</p>
        <p class="company-company-shuzi-num-text">Years  Experience
        </p>
    </div>
    <div class="company-company-shuzi-num">
      <p class="company-company-shuzi-num-title">200+</p>
      <p class="company-company-shuzi-num-text">Employees
      </p>
    </div>
    <div class="company-company-shuzi-num">
      <p class="company-company-shuzi-num-title">38636</p>
      <p class="company-company-shuzi-num-text">Square Meters
      </p>
    </div>
    <div class="company-company-shuzi-num">
      <p class="company-company-shuzi-num-title">4</p>
      <p class="company-company-shuzi-num-text">Production Lines
      </p>
    </div>

  </div>

<!--company-review-->
  <!--    home-custom-review-->
  <div  class="home-custom-review company-custom-review">
    <div class="home-custom-review-item">
      <div >
        <img src="../../public/img/home-custom-review-left.png" alt="">
      </div>

      <div class="company-custom-review-item">
        <p class="company-custom-review-item-title">
          OUR SERVICE
        </p>
          <div class="company-custom-review-item-content" v-if="itemNum==1">
            Because titanium is one of the most elastic metals in the world, cutting it is no easy task. The process of cutting titanium plates is further complicated due to their thermal reactivity. This means that it is impossible to cut titanium using conventional methods without changing its chemical composition. As an industry leader, Titanium Machina Center has extensive experience in the correct procedures for cold cutting of this metal using hydraulic metal cutting machines.
          </div>
        <div class="company-custom-review-item-content" v-if="itemNum==2">
          Water jet cutting is theoretically used for cutting any size of materials because of its fewer limitation regarding the thickness or size of the workpiece. The water jet can directly get special forms such as ring form from the sheet without breaking the peripheral workpiece. With water cooling during the process, there will be no oxidation of the workpiece, and no cutting stress remained. The cleaning of the water jet is also quite simple as there is only water and sand, rather than oil stains or emulsifiers.
        </div>
        <div class="company-custom-review-item-tab">
          <a >
          <div :class="['company-custom-review-item-tab-item',{'whatsactive':itemNum==1}]" @click="changeitem(1)">
            Titanium Plate Sawing
          </div>
          </a>
          <a >
          <div :class="['company-custom-review-item-tab-item',{'whatsactive':itemNum==2}]" @click="changeitem(2)">
            Water Jet Cutting Service
          </div>
          </a>
<!--          <div :class="['company-custom-review-item-tab-item',{'whatsactive':itemNum==3}]" @click="changeitem(3)">-->
<!--            Titanium Plate Sawing-->
<!--          </div>-->
        </div>
      </div>
<!--      <div class="home-custom-review-item-right">-->
<!--        <div class="home-custom-review-item-right-text-title">-->
<!--          CUSTOMER REVIEW-->
<!--        </div>-->
<!--        <div style="display: flex ;border-top: 1px">-->
<!--          <div class="home-custom-review-item-right-peo">-->
<!--            <div class="home-custom-review-item-right-peo-pic">-->
<!--              <img :src="this.picList[this.picIndex]" alt="">-->
<!--            </div>-->


<!--          </div>-->
<!--          <div class="home-custom-review-item-right-text">-->

<!--            <splide :options="option1"-->
<!--                    @splide:moved="onMounted">-->
<!--              <splide-slide><div class="home-custom-review-item-right-text-text">-->
<!--                We are happy to tell you that your titanium plates are perfect. We'd like to thank you again for your hospitality and help during our visit to your company-->
<!--              </div>-->
<!--                <div class="home-custom-review-item-right-text-text-peo">-->
<!--                  Tony  <span style="color:#258B3B;"> USA</span>-->
<!--                </div>-->
<!--              </splide-slide>-->
<!--              <splide-slide><div class="home-custom-review-item-right-text-text">-->
<!--                We are happy to tell you that your titanium plates are perfect. We'd like to thank you again for your hospitality and help during our visit to your company-->
<!--              </div>-->
<!--                <div class="home-custom-review-item-right-text-text-peo">-->
<!--                  Tony  <span style="color:#258B3B;"> USA</span>-->
<!--                </div>-->
<!--              </splide-slide>-->
<!--              <splide-slide><div class="home-custom-review-item-right-text-text">-->
<!--                We are happy to tell you that your titanium plates are perfect. We'd like to thank you again for your hospitality and help during our visit to your company-->
<!--              </div>-->
<!--                <div class="home-custom-review-item-right-text-text-peo">-->
<!--                  Tony  <span style="color:#258B3B;"> USA</span>-->
<!--                </div>-->
<!--              </splide-slide>-->
<!--              <splide-slide><div class="home-custom-review-item-right-text-text">-->
<!--                We are happy to tell you that your titanium plates are perfect. We'd like to thank you again for your hospitality and help during our visit to your company-->
<!--              </div>-->
<!--                <div class="home-custom-review-item-right-text-text-peo">-->
<!--                  Tony  <span style="color:#258B3B;"> USA</span>-->
<!--                </div>-->
<!--              </splide-slide>-->

<!--            </splide>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>

  </div>

<!--  company-team-group-->
  <div class="company-our-team">
    <p class="home-main-cate-title-common wow animate__animated animate__fadeInDown" >
      OUR TEAM GROUP
    </p>
    <div class="company-our-team-content">
      <div class="company-our-team-content-item">
        <img src="../../public/img/company-our-team-pic1.png" alt="" width="300px">
        <div class="company-our-team-content-item-title">
          The Technical Team
        </div>
        <div class="company-our-team-content-item-text">
          Over decades of accumulated R & D capacities, we provides standard titanium products and a variety of solutions to customized titanium solutions for customers.
        </div>
      </div>
      <div class="company-our-team-content-item"  >
        <img src="../../public/img/company-our-team-pic2.png" alt="" width="300px">
        <div class="company-our-team-content-item-title">
          The Production Team
        </div>
        <div class="company-our-team-content-item-text">
          We have more than 18 years experience in titanium industry and is a specialist of titanium provider in marketing. The annual output of titanium and titanium alloy plates of various grades is 3000 tons.
        </div>
      </div>
      <div class="company-our-team-content-item">
        <img src="../../public/img/company-our-team-pic3.png" alt="" width="300px">
        <div class="company-our-team-content-item-title">
          The QC Team
        </div>
        <div class="company-our-team-content-item-text">
          Quality control ensures that customers' products arrive on time and in an orderly manner.Product inspection at various stages of the manufacturing process is part of good manufacturing quality control.
        </div>
      </div>
      <div class="company-our-team-content-item">
        <img src="../../public/img/company-our-team-pic4.png" alt="" width="300px">
        <div class="company-our-team-content-item-title">
          The Business Team
        </div>
        <div class="company-our-team-content-item-text">
          Professional business team will connect with you to ensure that your requirements are accurately conveyed with no mistakes.
        </div>
      </div>
    </div>
  </div>
<!--  company-our-culture-->
  <div class="company-our-culture">
    <p class="home-main-cate-title-common wow animate__animated animate__fadeInDown" style="color:white;">
      OUR CULTURE
    </p>
    <div class="company-our-culture-content">
        <div class="company-our-culture-content-item">
            <div class="company-our-culture-content-item-left">
              <img src="../../public/img/company-our-culture-logo1-green.png" alt="" class="company-our-culture-content-item-left1">
              <img src="../../public/img/company-our-culture-logo1-white.png" alt="" class="company-our-culture-content-item-left2">

            </div>
          <div class="company-our-culture-content-item-right">
            <div class="company-our-culture-content-item-right-title">
                  Quality
            </div>
            <div class="company-our-culture-content-item-right-text">
              Provide quality products and considerate services that perfectly meet the customers' needs and expectations.
            </div>
          </div>
        </div>
      <div class="company-our-culture-content-item">
        <div class="company-our-culture-content-item-left">
          <img src="../../public/img/company-our-culture-logo2-green.png" alt="" class="company-our-culture-content-item-left1">
          <img src="../../public/img/company-our-culture-logo2-white.png" alt="" class="company-our-culture-content-item-left2">

        </div>
        <div class="company-our-culture-content-item-right">
          <div class="company-our-culture-content-item-right-title">
            Humanistic Company
          </div>
          <div class="company-our-culture-content-item-right-text">
            We take each employee's personal development into consideration and are willing to offer assistance.
          </div>
        </div>
      </div>
      <div class="company-our-culture-content-item">
        <div class="company-our-culture-content-item-left">
          <img src="../../public/img/company-our-culture-logo3-green.png" alt="" class="company-our-culture-content-item-left1">
          <img src="../../public/img/company-our-culture-logo3-white.png" alt="" class="company-our-culture-content-item-left2">

        </div>
        <div class="company-our-culture-content-item-right">
          <div class="company-our-culture-content-item-right-title">
            Team Work
          </div>
          <div class="company-our-culture-content-item-right-text">
            We work as a team, constantly learn from others' strengths and complement our own weaknesses.
          </div>
        </div>
      </div>
    </div>
  </div>
  <Bottom></Bottom>
</div>
</template>

<script>
// import {Splide,SplideSlide} from  '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import {WOW} from 'wowjs'
import Bottom from "@/components/Bottom";
export default {
name: "Company",
  components:{
  // Splide,
  //   SplideSlide
    Bottom
  },
  data(){
  return{
    picIndex:0,
    picList:[
      '/img/home-rentou7.png',
      '/img/home-rentou6.png',
      '/img/home-rentou5.png',
      '/img/home-rentou1.png',
      '/img/home-rentou3.png',
      '/img/home-rentou2.png',
    ],
    itemNum:1,
    videocanshu:0,
  }
  },
  methods:{
    onMounted() {

      if (this.picIndex<6){
        this.picIndex=this.picIndex+1
      }
      if(this.picIndex==6){
        this.picIndex = 0
      }
// console.log(this.picIndex)
    },
    changeitem(e){
this.itemNum = e
    },
    changeVideo(e){
      this.videocanshu =e
    },
  },
  mounted() {
  new WOW().init()
  }
}
</script>

<style scoped>

</style>

