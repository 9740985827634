<template>
  <div class="blank">
  <div class="technology" v-show="weizhi==1||weizhi==5||weizhi==2||weizhi==3||weizhi==4||weizhi==6" id="technology" style="overflow: hidden"  @wheel="handleWheel"  >
    <div class="technology-left"   id='technology-left' style="position: absolute;height: 4290px; width:50%;top: -4290px;left: 0px; transition: all 1s;" >
<!--      <div  style="height: 2140px;background-color: white; ">-->

<!--      </div>-->
      <div class="technology-left-item">
        <div class="support-2-left-top">
          <p class="support-2-left-top-title">
            Chemical Composition
          </p>
          <p class="support-2-left-top-title-small">
            Chemical Analysis
          </p>
          <p class="support-2-left-top-title-text">
            Chemical analysis refers to the method of determining the chemical composition. Through composition analysis, we can confirm whether the composition of the material meets the requirements, and make precise technical adjustments to the production process to meet more stringent requirements.</p>
        </div>
        <div class="support-2-left-bottom">
          <img src="../../public/img/9.png" alt="" width="100%" height="464">
        </div>
      </div>

      <div class="technology-left-item">
      <div class="support-2-left-top">
        <p class="support-2-left-top-title">
          Metallographic Laboratory
        </p>
        <p class="support-2-left-top-title-small">
          Microstructure
        </p>
        <p class="support-2-left-top-title-text">The metallographic laboratory can detect the internal structure of metal materials, which can only be observed under a microscope. Through metallographic analysis, it is possible to identify the structure and grain size rating after various cold and hot processing treatments, and verify the accuracy of the production process. It can also identify internal defects or inclusions and judge the stability and uniformity of the material composition.</p>
      </div>
      <div class="support-2-left-bottom">
        <img src="../../public/img/7.png" alt="" width="100%" height="464">
      </div>
      </div>
      <div class="technology-left-item">
        <div class="support-2-left-top">
          <p class="support-2-left-top-title">
            Vacuum Annealing Furnace
          </p>
          <p class="support-2-left-top-title-small">
            Vacuum Annealing Furnace
          </p>
          <p class="support-2-left-top-title-text">Titanium has a relatively strong affinity with gases at high temperatures and will form a dense oxide film, which is difficult to treat. Vacuum annealing can prevent the oxidation reaction of titanium, and make the titanium clean and pollution-free.</p>
        </div>
        <div class="support-2-left-bottom">
          <img src="../../public/img/5.png" alt="" width="100%" height="464">
        </div>
      </div>
      <div class="technology-left-item">

          <div class="support-2-left-top">
            <p class="support-2-left-top-title">
              Cold Rolling
            </p>
            <p class="support-2-left-top-title-small">
              Cold Rolling Machine
            </p>
            <p class="support-2-left-top-title-text">
              Cold rolling process is to roll the materials at room temperature. The difference from hot rolling is that cold rolling does not require heating of the materials and there will be no oxide on the surface. Without the oxide, the surface will be smoother, and at the same time, because the roller is not affected by oxide scale, the surface is not easy to become rough, which also ensures the smoothness of sheets.</p>
          </div>
          <div class="support-2-left-bottom">
            <img src="../../public/img/3.png" alt="" width="100%" height="464">
          </div>
      </div>
      <div class="technology-left-item">
        <div class="support-1-left support-2-left">
          <div class="support-2-left-top">
            <p class="support-2-left-top-title">
              Vacuum Plasma
            </p>
            <p class="support-2-left-top-title-small">
              Vacuum Plasma Welding Chamber
            </p>
            <p class="support-2-left-top-title-text">Plasma welding has the characteristics of concentrated energy, high productivity, fast welding speed, small stress and deformation, and stable electric isolation. The vacuum state avoids oxidation and prevents the increase of oxygen content during electrode welding. In response to this, our vacuum plasma welding chamber can complete the butt welding of electrodes efficiently and without pollution.</p>
          </div>
          <div class="support-2-left-bottom">
            <img src="../../public/img/1.png" alt="" width="100%" height="464">
          </div>
        </div>
      </div>
      <div class="technology-left-item technology-left-item-last" >
            <p class="support-1-left-title">
              OUR EQUIPMENT
            </p>
        <p class="support-1-left-text">In China, we currently have 38,636.22 square meters of manufacturing and warehouse space. To best serve our customers, our manufacturing facilities will feature more expand work centers. We are proud to be ISO 9001, AS9100 and PED2014/68/EU Quality Certified. We offer a comprehensive stock inventory of titanium, and titanium alloy products. A full selection of processes including hot rolling, cold rolling, heat treatment, straightness and surface treatment allows us to meet a variety of customer needs.
        </p>

      </div>
    </div>

    <div class="technology-right"   id='technology-right' style="position: absolute;height: 4290px; width:50%;top: 0px;left:50%;transition: all 1s;" >
<!--      <div  style="height: 1500px;background-color: #000000; ">-->
<!--        -->
<!--      </div>-->
      <div class="technology-right-item" >
        <img src="../../public/img/support-1-right-back.png" alt="" width="100%" height="858px">
      </div>

      <div class="technology-right-item">
<!--        <img src="../../public/img/support-2-right.png" alt="" width="100%" height="462">-->
        <img src="../../public/img/2.png" alt="" width="100%" height="462">
        <div class="support-2-left-top">
          <p class="support-2-left-top-title">
            Hot Rolling
          </p>
          <p class="support-2-left-top-title-small">
            Hot Rolling Machine
          </p>
          <p class="support-2-left-top-title-text">Hot rolling equipment can significantly reduce energy consumption and reduce costs. During hot rolling, the metal has high plasticity and low deformation resistance, which greatly reduces the energy consumption of metal deformation. Hot rolling can improve the processing performance of metals and alloys. That is, the coarse grains in the as-cast state are broken and then reorganize into a fine and uniform equiaxed structure, thereby improving the processing performance of the alloy.</p>
        </div>
      </div>


      <div class="technology-right-item">
        <div class="support-2-left-bottom">
          <img src="../../public/img/4.png" alt="" width="100%" height="462">
        </div>
        <div class="support-2-left-top">
          <p class="support-2-left-top-title">
            Straightness
          </p>
          <p class="support-2-left-top-title-small">
            Straightness Furface
          </p>
          <p class="support-2-left-top-title-text">
            We stack the titanium sheets/ plates neatly as per the sizes and specification into the straightness furnace, and cover the partition and steel plates on the top. We heat the straightness furnace to the specified certain temperature and keep it for a sufficient time to improve the flatness of sheets and plates. This treatment can effectively reduce the internal residual stress of the materials and make the residual stress distribution uniform,
            thereby ensuring the flatness of the plate.
          </p>
        </div>
      </div>
      <div class="technology-right-item">
        <div class="support-2-left-bottom">
          <img src="../../public/img/6.png" alt="" width="100%" height="462">
        </div>
        <div class="support-2-left-top">
          <p class="support-2-left-top-title">
            Sample Processing Center
          </p>
          <p class="support-2-left-top-title-small">
            Sample Preparation
          </p>
          <p class="support-2-left-top-title-text">In order to ensure the accuracy of laboratory test data, the samples we use for testing need to be pre-processed to ensure that the deviation of the test specimen is reduced to a minimum. Treatment methods include wire cutting, milling machine, lathe, grinding and polishing, cleaning, corrosion, etc.</p>
        </div>
      </div>


      <div class="technology-right-item">
        <div class="support-2-left-bottom">
          <img src="../../public/img/8.png" alt="" width="100%" height="462">
        </div>
        <div class="support-2-left-top">
          <p class="support-2-left-top-title">
            Tensile Testing
          </p>
          <p class="support-2-left-top-title-small">
            Tensile Testing Machine
          </p>
          <p class="support-2-left-top-title-text">
            The tensile testing machine is a combinated product of modern electronic technology and mechanical transmission technology. It is a large-scale precision testing instrument that makes full use of the respective advantages of electromechanics. It can stretch, compress, and bending, etc. It has the advantages of high precision, wide speed range, compact structure, convenient operation, stable performance, reliable work and high efficiency. It can display, record and print test data promptly.
          </p>
        </div>
      </div>

      <div class="technology-right-item">
        <div class="support-2-left-bottom">
          <img src="../../public/img/10.png" alt="" width="100%" height="462">
        </div>
        <div class="support-2-left-top">
          <p class="support-2-left-top-title">
            Hardness
          </p>
          <p class="support-2-left-top-title-small">
            Hardness Test
          </p>
          <p class="support-2-left-top-title-text">
            Hardness indicates the ability of a material to resist hard objects pressed into its surface. It is one of the important performance indicators of metal materials. Generally, the higher the hardness is, the better the wear resistance. The hardness test is the simplest and most feasible test method in the mechanical performance test. When other test data is not available, it can be used as a convenient method to analyse the material.
          </p>
        </div>
      </div>
    </div>


<!--        <div class="support-workshop-display">-->
<!--          <p class="home-banner-content-text-title wow animate__animated animate__fadeInDown">-->
<!--            WORKSHOP DISPLAY-->
<!--          </p>-->
<!--          <div class="support-workshop-display-inner">-->
<!--            <div class="support-workshop-display-inner-item">-->
<!--              <img src="../../public/img/workshop-dis-pic1.png" alt="">-->
<!--              <p class="support-workshop-display-inner-item-text">-->
<!--                Cold Rolling Workshop-->
<!--              </p>-->
<!--            </div>-->
<!--            <div class="support-workshop-display-inner-item">-->
<!--              <img src="../../public/img/workshop-dis-pic2.png" alt="">-->
<!--              <p class="support-workshop-display-inner-item-text">-->
<!--                Hot Rolling Workshop-->
<!--              </p>-->
<!--            </div>-->
<!--            <div class="support-workshop-display-inner-item">-->
<!--              <img src="../../public/img/workshop-dis-pic3.png" alt="">-->
<!--              <p class="support-workshop-display-inner-item-text">-->
<!--                Smelting Workshop-->
<!--              </p>-->
<!--            </div>-->
<!--            <div class="support-workshop-display-inner-item">-->
<!--              <img src="../../public/img/workshop-dis-pic4.png" alt="">-->
<!--              <p class="support-workshop-display-inner-item-text">-->
<!--                Quality Inspection Workshop-->
<!--              </p>-->
<!--            </div>-->
<!--            <div class="support-workshop-display-inner-item">-->
<!--              <img src="../../public/img/workshop-dis-pic5.png" alt="">-->
<!--              <p class="support-workshop-display-inner-item-text">-->
<!--                Packing Workshop-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->


<!--        <div class="support-workshop-display2">-->
<!--          &lt;!&ndash;            <p class="support-workshop-display2-title">&ndash;&gt;-->
<!--          &lt;!&ndash;              WORKSHOP<br>&ndash;&gt;-->
<!--          &lt;!&ndash;              DISPLAY&ndash;&gt;-->
<!--          &lt;!&ndash;            </p>&ndash;&gt;-->
<!--        </div>-->


<!--    <div style="height: 4000px;background: yellow;">-->

<!--    </div>-->
  </div>
    <div class="tec-bottom" id="tec-bottom" @wheel="handleWheelNew" v-if="weizhi==7">

      <div class="support-workshop-display">
        <div class=""></div>
        <p class="home-banner-content-text-title wow animate__animated animate__fadeInDown" style="text-align: center ">
          WORKSHOP DISPLAY
        </p>
        <div class="support-workshop-display-inner">

          <div class="support-workshop-display-inner-item" style="margin-left: 21px">
            <img src="../../public/img/workshop-dis-pic3.png" alt="">
            <p class="support-workshop-display-inner-item-text">
              Melting Workshop
            </p>
          </div>
          <div class="support-workshop-display-inner-item" style="margin-left: 21px">
            <img src="../../public/img/workshop-dis-pic2.png" alt="">
            <p class="support-workshop-display-inner-item-text">
              Hot Rolling Workshop
            </p>
          </div>
          <div class="support-workshop-display-inner-item" style="margin-left: 21px">
            <img src="../../public/img/workshop-dis-pic1.png" alt="">
            <p class="support-workshop-display-inner-item-text">
              Cold Rolling Workshop
            </p>
          </div>
          <div class="support-workshop-display-inner-item" style="margin-left: 228px">
            <img src="../../public/img/workshop-dis-pic4.png" alt="">
            <p class="support-workshop-display-inner-item-text">
              Quality Inspection Workshop
            </p>
          </div>
          <div class="support-workshop-display-inner-item" style="margin-left: 21px">
            <img src="../../public/img/workshop-dis-pic5.png" alt="">
            <p class="support-workshop-display-inner-item-text">
              Packing Workshop
            </p>
          </div>
        </div>
      </div>


      <div class="support-workshop-display2">
        <!--            <p class="support-workshop-display2-title">-->
        <!--              WORKSHOP<br>-->
        <!--              DISPLAY-->
        <!--            </p>-->
      </div>
      <Bottom></Bottom>
    </div>
  </div>

</template>

<script>
import Bottom from "@/components/Bottom";
export default {
name: "tecNew",
  components:{
  Bottom
  },
  data(){
  return{
    weizhi:1,
height:0
  }
  },
  create(){
  this.gaibian()
  },
  methods:{
    throttle (fn, gapTime) {
      let _this = this
      return function () {
        let _nowTime = +new Date()
        if (_nowTime - _this._lastTime > gapTime || !_this._lastTime) {
          fn(...arguments)// 函数可以带参数
          _this._lastTime = _nowTime
        }
      }
    },
    handleWheel (e) {
      e.stopPropagation()
      this.throttle(this.pageUpOrDown, 600)(e)
      // window.scrollTo(0,0);
    },
    handleWheelNew(e){
      e.stopPropagation()
      this.throttle(this.pageUpOrDown1, 0)(e)
    },
    pageUpOrDown (e) {
      if (this.weizhi>=1&&this.weizhi<=6){
      if (e.deltaY > 0) {
        // this.weizhi+=1
       this.height -=858
this.weizhi+=1
        //向下
        document.getElementById('technology-left').style.top= -4290 - this.height+'px'//执行的事件逻辑。比如向下翻页
        // window.scrollTo(0,0);
        document.getElementById('technology-right').style.top= this.height+'px'//执行的事件逻辑。比如向下翻页
      }
      else if (e.deltaY < 0 &&this.weizhi>1) {
        //向上
        this.weizhi-=1
        this.height +=858

        document.getElementById('technology-right').style.top= this.height+'px'//执行的事件逻辑。比如向下翻页
        document.getElementById('technology-left').style.top= -4290 - this.height+'px'
        // console.log('2')//比如向上翻页
      }
      }
     // document.body.scrollTo(0,0);
       console.log(this.weizhi)


   },
    pageUpOrDown1(e){
      if(document.documentElement.scrollTop==0&&e.deltaY < 0){
this.weizhi=6
        this.height = -4290
        document.getElementById('technology-right').style.top=  this.height+'px'//执行的事件逻辑。比如向下翻页
        document.getElementById('technology-left').style.top= -4290-this.height+'px'

      }
      console.log(document.documentElement.scrollTop)
      console.log(this.weizhi)
    },
    gaibian(){
      if (this.weizhi>=6){
        // document.getElementById('technology').style.overflow ="scroll"
      }
    }
  },
  // mounted() {
  //   //此处true 需要加上 不加滚动事件可能绑定不成功
  //   window.addEventListener("scroll", this.handleScroll, true);
  // },
}
</script>

<style scoped>
.container-box{
  background: yellow;
  position: relative;
}
.technology{
  height: calc(100vh - 110px);
  position: relative;

  width: 100%;
}
.technology-right-item{
  height: 858px;
}.technology-left-item{
  height: 858px;
 }
.technology-left-item-last{
  background: url("/img/support-1-left-back.png");
  height: 858px;
  background-size:100% 100% ;
}
</style>
