<template>
<div class="support" >
      <swiper
      :slides-per-view="3"
      :space-between="50"
      :options="swiperoption"
      @swiper="onSwiper"
      @slideChange=" onSlideChange">

        <swiper-slide>
  <div class="support-content">
  <div class="support-1-left">
    <p class="support-1-left-title">OUR EQUIPMENT</p>
    <p class="support-1-left-text">In China, we currently have 38,636.22 square meters of manufacturing and warehouse space. To best serve our customers, our manufacturing facilities will feature more expand work centers. We are proud to be ISO 9001 and AS9100 Quality Certified. We offer a comprehensive stock inventory of titanium, and titanium alloy products. A full selection of finishing options including hot rolling, cold rolling, heat treatment, pickling and surface treatment allows us to meet a variety of customer needs.</p>
  </div>
  <div class="support-1-right">
    <img src="../../public/img/support-1-right-back.png" alt="" width="100%" height="100%">
  </div>
  </div>
</swiper-slide>
        <swiper-slide>
  <div class="support-content">

    <div class="support-1-left support-2-left">
        <div class="support-2-left-top">
            <p class="support-2-left-top-title">
              OUR CAPABILITY
            </p>
          <p class="support-2-left-top-title-small">
            Pit Annealing Furnace
          </p>
          <p class="support-2-left-top-title-text">Through our pit annealing furnace, titanium and titanium alloys are treated to increase strength and reduce residual stresses developed during fabrication.</p>
        </div>
      <div class="support-2-left-bottom">
        <img src="../../public/img/support-2-left.png" alt="">
      </div>
    </div>
    <div class="support-1-right">
      <div class="support-2-left-bottom">
        <img src="../../public/img/support-2-right.png" alt="">
      </div>
      <div class="support-2-left-top">
        <p class="support-2-left-top-title">
          OUR CAPABILITY
        </p>
        <p class="support-2-left-top-title-small">
          Vacuum Plasma Welding Chamber
        </p>
        <p class="support-2-left-top-title-text">The normal welding process has low welding efficiency while high labor cost. Our vacuum plasma welding chamber can work under high speed, greater penetration, so that full penetration butt weld is achievable.</p>
      </div>
    </div>
  </div>

</swiper-slide>
        <swiper-slide>
          <div class="support-content">

            <div class="support-1-left support-2-left">
              <div class="support-2-left-top">
                <p class="support-2-left-top-title">
                  OUR CAPABILITY
                </p>
                <p class="support-2-left-top-title-small">
                  Gag Straightening Furnace
                </p>
                <p class="support-2-left-top-title-text">The gag straightening furnace is designed to remove sweep and camber, and straighten square and rectangle titanium tubing to very tight tolerances.</p>
              </div>
              <div class="support-2-left-bottom">
                <img src="../../public/img/support-3-left.png" alt="">
              </div>
            </div>
            <div class="support-1-right">
              <div class="support-2-left-bottom">
                <img src="../../public/img/support-3-right.png" alt="">
              </div>
              <div class="support-2-left-top">
                <p class="support-2-left-top-title">
                  OUR CAPABILITY
                </p>
                <p class="support-2-left-top-title-small">
                  Vacuum Annealing Furnace
                </p>
                <p class="support-2-left-top-title-text">The use of vacuum annealing to anneal titanium pipes is a relatively new technology. It attracts more attention recently because of its clean, economical, non-polluting, non-oxidizing, and small deformation advantages.</p>
              </div>
            </div>
          </div>

        </swiper-slide>
        <swiper-slide>
          <div class="support-content">

            <div class="support-1-left support-2-left">
              <div class="support-2-left-top">
                <p class="support-2-left-top-title">
                  OUR CAPABILITY
                </p>
                <p class="support-2-left-top-title-small">
                  Metallographic Laboratory
                </p>
                <p class="support-2-left-top-title-text">In our metallographic laboratory, the hardness of titanium is detected. Also, a series of tests such as high and low magnification inspections, metal microstructure inspections and non-metallic inclusions microscopic inspections can be conducted. </p>
              </div>
              <div class="support-2-left-bottom">
                <img src="../../public/img/support-4-left.png" alt="">
              </div>
            </div>
            <div class="support-1-right">
              <div class="support-2-left-bottom">
                <img src="../../public/img/support-4-right.png" alt="">
              </div>
              <div class="support-2-left-top">
                <p class="support-2-left-top-title">
                  OUR CAPABILITY
                </p>
                <p class="support-2-left-top-title-small">
                  Electronic Universal Testing Machine
                </p>
                <p class="support-2-left-top-title-text">With the benefits of high efficiency, low noise and non-pollution, it can complete the tensile, compression, bending, shearing and other mechanical performance tests of our sample product. </p>
              </div>
            </div>
          </div>

        </swiper-slide>
        <swiper-slide>
          <div class="support-content">

            <div class="support-1-left support-2-left">
              <div class="support-2-left-top">
                <p class="support-2-left-top-title">
                  OUR CAPABILITY
                </p>
                <p class="support-2-left-top-title-small">
                  Sample Processing Center
                </p>
                <p class="support-2-left-top-title-text">Pure titanium is soft and ductile, but is very easily damaged by twinning in sectioning and grinding. Preparation of commercially pure titanium includes necessary sample processing so that it can be totally ready for the further production process.</p>
              </div>
              <div class="support-2-left-bottom">
                <img src="../../public/img/support-5-left.png" alt="">
              </div>
            </div>
            <div class="support-1-right">
              <div class="support-2-left-bottom">
                <img src="../../public/img/support-5-right.png" alt="">
              </div>
              <div class="support-2-left-top">
                <p class="support-2-left-top-title">
                  OUR CAPABILITY
                </p>
                <p class="support-2-left-top-title-small">
                  Hot Rolling Machine
                </p>
                <p class="support-2-left-top-title-text">Through the hot rolling process of titanium materials, we can achieve large-scale production under low energy consumption. During this, the as-cast structure is transformed into a processed structure and the plasticity of titanium l is greatly improved through the transformation of the structure.</p>
              </div>
            </div>
          </div>

        </swiper-slide>
<swiper-slide>


  <div class="swiper-container" id="swiper-container2" :options="swiperoption2" >
    <div class="swiper-wrapper ">
      <div class="swiper-slide">
<!--        <h4>一段很长的内容</h4>-->
<!--        <p style="opacity:0.99;">一<br>段<br>很<br>长<br>的<br>内<br>容</p>-->
<!--        <p style="opacity:0.98;">一<br>段<br>很<br>长<br>的<br>内<br>容</p>-->
<!--        <p style="opacity:0.97;">一<br>段<br>很<br>长<br>的<br>内<br>容</p>-->
        <div class="support-workshop-display">
          <p class="home-banner-content-text-title wow animate__animated animate__fadeInDown">
            WORKSHOP DISPLAY
          </p>
          <div class="support-workshop-display-inner">
                <div class="support-workshop-display-inner-item">
                  <img src="../../public/img/workshop-dis-pic1.png" alt="">
                  <p class="support-workshop-display-inner-item-text">
                    Cold Rolling Workshop
                  </p>
                </div>
            <div class="support-workshop-display-inner-item">
              <img src="../../public/img/workshop-dis-pic2.png" alt="">
              <p class="support-workshop-display-inner-item-text">
                Hot Rolling Workshop
              </p>
            </div>
            <div class="support-workshop-display-inner-item">
              <img src="../../public/img/workshop-dis-pic3.png" alt="">
              <p class="support-workshop-display-inner-item-text">
                Smelting Workshop
              </p>
            </div>
            <div class="support-workshop-display-inner-item">
              <img src="../../public/img/workshop-dis-pic4.png" alt="">
              <p class="support-workshop-display-inner-item-text">
                Quality Inspection Workshop
              </p>
            </div>
            <div class="support-workshop-display-inner-item">
              <img src="../../public/img/workshop-dis-pic5.png" alt="">
              <p class="support-workshop-display-inner-item-text">
                Packing Workshop
              </p>
            </div>
          </div>
        </div>


        <div class="support-workshop-display2">
<!--            <p class="support-workshop-display2-title">-->
<!--              WORKSHOP<br>-->
<!--              DISPLAY-->
<!--            </p>-->
        </div>
        <div class="support-tec-zhengshu">
          <p class="home-banner-content-text-title wow animate__animated animate__fadeInDown" style="padding-bottom: 50px">
            TECHNICAL CERTIFICATION
          </p>
          <carousel-3d  :width="320" :height="470" :space="500" :perspective="40">
            <slide :index="0" >
              <img src="../../public/img/support-zhengshu1.png" alt="">
            </slide>
            <slide :index="1">
              <img src="../../public/img/support-zhengshu2.png" alt="">
            </slide>
            <slide :index="2">
              <img src="../../public/img/support-zhengshu3.png" alt="">
            </slide>
          </carousel-3d>
        </div>
        <Bottom></Bottom>
        <div style="height: 480px"></div>
      </div>
      <div class="swiper-scrollbar" slot="scrollbar"></div>
    </div>

  </div>
</swiper-slide>

      </swiper>
<!--  <div class="head-inner">123</div>-->


</div>
</template>

<script>
import Bottom from "@/components/Bottom";
import Swiper from 'swiper'
// import {WOW} from "wowjs";

export default {
name: "Support",
components:{
  Bottom
},
data(){
  return{
    swiperoption:{
      // loop:true,
      direction:'vertical',
      mousewheel: true,
      height:859,
      // effect:'fade',
      // fadeEffect:{
      //   crossFade:false
      // }
      cubeEffect: {
        slideShadows: false,
      },
      // scrollbar: {
      //   el: '.swiper-scrollbar',
      // },

    },
    swiperoption2:{
      direction: 'vertical',
      mousewheel: true,
      nested:true,
      //resistanceRatio: 0,
      slidesPerView: 'auto',
      freeMode: true,
      // height:3500,
      scrollbar: {
        el: '.swiper-scrollbar',
      },
    }



  }
},
  methods:{
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log('slide change');
    },

  },
  mounted() {
    new Swiper('#swiper-container2',{//子swiper
      direction: 'vertical',
      mousewheel: true,
      nested:true,
      //resistanceRatio: 0,
      slidesPerView: 'auto',
      freeMode: true,

      scrollbar: {
        el: '.swiper-scrollbar',
      },})
        // new WOW().init()
        // new WOW().init()
  },


}

</script>

<style scoped>
html, body {
  position: relative;
  height: 100%;
}
body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color:#000;
  margin: 0;
  padding: 0;
}
.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-fslexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
#swiper-container2 .swiper-slide {
  font-size: 18px;
  height: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display:block;
  /*line-height:100px;*/
  /*background: #4390EE;*/
  color: #fff;
}
</style>
