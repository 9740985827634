<template>

    <div id="PcTest">

    </div>
</template>
  <script>
    // import Swiper from 'swiper'
    // import 'swiper/css/swiper.min.css'
    export default {
      data () {
        return {
          mySwiper1: null,
          mySwiper2: null
        }
      },
      created() {
        this.$router.push({path:'newsDe',query:{id:this.$route.query.id,catid:this.$route.query.catid}})
      }

    }
  </script>
  <style lang="less" scoped>
    html, body {
      position: relative;
      height: 100%;
    }
    body {
      background: #eee;
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
      font-size: 14px;
      color:#000;
      margin: 0;
      padding: 0;
    }
    #PcTest {
      width: 100%;
      height: 100vh
    }
    .swiper-container {
      width: 100%;
      height: 100%;
    }
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
    }
    #swiper-container2 .swiper-slide {
      font-size: 18px;
      height: auto;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display:block;
      line-height:100px;
      background: #4390EE;
      color: #fff;
    }
  </style>

