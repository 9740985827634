<template>
<div class="product-de">
  <div  class="product-banner">
    <div class="product-banner-inner">
      <div class="product-banner-inner-left">
        CLASSSIFICATION
      </div>
      <div class="product-banner-inner-right">
        Find The Best Titanium You Want At Shen Ji
      </div>
    </div>
  </div>
  <!--line-->
  <div class="product-line">
    <div class="product-line-inner">
      <div>
        <router-link to="/" style="color:white;">
        Home
        </router-link>
      </div>
      <div>/</div>
      <div>product</div>
    </div>
  </div>
<!--product-de-content -->
  <div class="product-de-content">
    <div class="product-de-content-inner">
      <div class="product-de-content-top">
      <div class="product-de-content-top-left">
        <img :src="'http://cdn.fish-cloud.com/'+shopDetail.shopImg" alt=""  width="100%">
      </div>
        <div class="product-de-content-top-right">
            <p class="product-de-content-top-right-title">
              {{ this.shopDetailTitle }}
            </p>
          <p class="product-de-content-top-right-text">
            {{this.shopDetailText }}
          </p>
          <a >
          <div class="product-de-content-top-right-inquiry"  @click="showModal">
            Inquiry
          </div>
          </a>
          <a-modal
              title="Title"
              :visible="visible"
              :confirm-loading="confirmLoading"
              @ok="handleOk"
              @cancel="handleCancel"
          >
            <div class="md-area">
              <div class="md-area-item">
                <p>Name</p>
                <input type="text" v-model="PushxundanList.name"></div>
              <div class="md-area-item">
                <p>Email</p>
                <input type="text" v-model="PushxundanList.email"></div>
              <div class="md-area-item">
                <p>TEL</p>
                <input type="text" v-model="PushxundanList.contactTel"></div>
              <div class="md-area-item">
                <p>Quantity</p>
                <input type="number" v-model="PushxundanList.quantity"></div>
              <div class="md-area-item md-area-item-yanzheng">
                <p>VerifyCode</p>
                <input type="text" v-model="PushxundanList.VerifyCode"> <span @click="changeyanzheng">
            <img :src="yanzheng" alt=""></span></div>
              <div class="md-area-item md-area-item-mes">
                <p>MESSAGE</p>
                <a-textarea
                    v-model="PushxundanList.message"
                    :auto-size="{ minRows: 3, maxRows: 5 }"
                />
                <!--                              placeholder=""-->
              </div>
            </div>
          </a-modal>
<div class="product-de-content-top-right-shemei">
  <div ><img src="../../public/img/facebook.png" alt=""></div>
  <div ><img src="../../public/img/niao.png" alt=""></div>
  <div ><img src="../../public/img/ins.png" alt=""></div>

</div>
        </div>
      </div>

      <div class="product-de-content-canshu">

        <img src="../../public/img/tou.jpg" alt="" v-show="tou == 245">
          <swiper :options="xgOption">
            <swiper-slide v-for="item of imgList" :key="item.num">
              <img :src="'http://cdn.fish-cloud.com/'+item" alt=""  width="100%">
            </swiper-slide>
            <!-- <swiper-slide>
              <router-link to="details"><img src="/img/pro5.png" width="100%" alt=""></router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link to="details"><img src="/img/pro5.png" width="100%" alt=""></router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link to="details"><img src="/img/pro5.png" width="100%" alt=""></router-link>
            </swiper-slide> -->
          </swiper>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>


      </div>

      <div class="product-de-content-application">
        <p class="home-main-cate-title-common" >
          APPLICATION
        </p>
        <div class="product-de-content-application-content">
              <div  class="product-de-content-application-content-item">
                <img src="../../public/img/pro-de-app-pic1.png" alt="">
                <div class="product-de-content-application-content-item-text">
                  <p class="product-de-content-application-content-item-text-title">
                    Commerical
                  </p>
                  <p class="product-de-content-application-content-item-text-text">
                    With the development of titanium industry, the characteristics and benefits of titanium will be more and more obvious.In these applications, titanium is promoted as a lightweight, durable, and stylish metal.
                  </p>
                </div>
              </div>
          <div  class="product-de-content-application-content-item">
            <img src="../../public/img/pro-de-app-pic2.png" alt="">
            <div class="product-de-content-application-content-item-text">
              <p class="product-de-content-application-content-item-text-title">
                Medical
              </p>
              <p class="product-de-content-application-content-item-text-text">
                Titanium is selected as the metal of choice for the dental market due to its complete resistance to bodily fluid attack, high strength and low modulus. Apart from titanium implants, titanium braces are also stronger, lighter, and more compatible with body fluids and bone density than traditional steel braces.
              </p>
            </div>
          </div>
          <div class="product-de-content-application-content-item">
            <img src="../../public/img/pro-de-app-pic3.png" alt="">
            <div class="product-de-content-application-content-item-text">
              <p class="product-de-content-application-content-item-text-title">
                Aerospace
              </p>
              <p class="product-de-content-application-content-item-text-text">
                We currently offer many advanced specialty alloys. These alloys support many aerospace applications, such as airframes, landing gear and fasteners. Creative programming to support specific locations and/or systems, such as a family of alloys and products, to support production operations.
              </p>
            </div>
          </div>
          <div  class="product-de-content-application-content-item">
            <img src="../../public/img/pro-de-app-pic4.png" alt="">
            <div class="product-de-content-application-content-item-text">
              <p class="product-de-content-application-content-item-text-title">
                Military
              </p>
              <p class="product-de-content-application-content-item-text-text">
                Titanium can be found in military and commercial helicopters. In this application, the design criteria for titanium includes the reliability of the use of titanium and the strength to weight ratio of titanium. Titanium has been designed into every major helicopter program, both commercial and military.
              </p>
            </div>
          </div>

        </div>
      </div>
      <div class="product-de-content-relate">
        <p class="home-main-cate-title-common" >
      FIND WHAT YOU NEED
        </p>
        <div class="product-de-content-relate-content">
          <div class="product-content-area">
            <div class="home-now-recommended-content-item product-content-area-item  " v-for="item in relaList " :key="item.num">
              <router-link :to="{
                path:'blank',
                      query:{id:item.id},

              }" target="_blank">
              <img :src="'http://cdn.fish-cloud.com/'+item.img" alt="">
              <div class="home-now-recommended-content-item-text">
                <div >

                </div>
                <div >

                  {{item.name }}

                </div>

                <div></div>

              </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="product-customer-review" style="height: 470px">

    <div class="home-custom-review-item-right product-custom-review-item-right pro-de-custom-review-item-right">
      <div class="home-custom-review-item-right-text-title">
        CUSTOMER REVIEW
      </div>
      <div style="display: flex ;border-top: 1px">
        <div class="home-custom-review-item-right-peo">
          <div class="home-custom-review-item-right-peo-pic">
            <img :src="this.picList[this.picIndex]" alt="">
          </div>


        </div>
        <div class="home-custom-review-item-right-text">

          <splide :options="option1"
                  @splide:moved="onMounted">
            <splide-slide><div class="home-custom-review-item-right-text-text">
              We are happy to tell you that your titanium plates are perfect. We'd like to thank you again for your hospitality and help during our visit to your company.
            </div>
              <div class="home-custom-review-item-right-text-text-peo">
                Tony  <span style="color:#258B3B;"> UK</span>
              </div>
            </splide-slide>
            <splide-slide><div class="home-custom-review-item-right-text-text">
              Our goods arrived on time without any delay. Thank you very much for completing such a large order perfectly. Looking forward to our next cooperation!
            </div>
              <div class="home-custom-review-item-right-text-text-peo">
                Alice  <span style="color:#258B3B;"> Australia</span>
              </div>
            </splide-slide>
            <splide-slide><div class="home-custom-review-item-right-text-text">
              The products you designed perfectly meet our requirements, and our ideas have been well presented. After the installation, we are very pleased with the way it looked.
            </div>
              <div class="home-custom-review-item-right-text-text-peo">
                Elaine  <span style="color:#258B3B;"> Canada</span>
              </div>
            </splide-slide>
            <splide-slide><div class="home-custom-review-item-right-text-text">
              Everything is great. Very happy to work with Tina, who has helped us get everything straight. We will certainly order again next time!
            </div>
              <div class="home-custom-review-item-right-text-text-peo">
                Jane  <span style="color:#258B3B;"> Russia</span>
              </div>
            </splide-slide>

          </splide>
        </div>
      </div>
    </div>


  </div>
  <Bottom style="margin-top: 100px"></Bottom>
</div>
</template>

<script>
import {Splide,SplideSlide} from  '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Bottom  from "@/components/Bottom";
export default {
  components:{
    SplideSlide,
    Splide,
    Bottom,

  },

name: "ProductD",
  data(){
  return{
    option1:{
      perPage:1,
      // gap:10,
      type:'loop',
      perMove:1,
      autoplay:true,
      interval:2000,
      width:1300
    },
    picIndex:0,
    picList:[
      '/img/home-rentou7.png',
      '/img/home-rentou6.png',
      '/img/home-rentou5.png',
      '/img/home-rentou1.png',
    ],
    shopDetail:{},
    shopDetailText:'',
    shopDetailTitle:'',
    PushxundanList:{
      shopID:'',
      shopSku:'',
      name:'',
      email:'',
      quantity: 0,
      contactName: '',
      contactEMail: "",
      contactCompany: "",
      contactTel: "",
      message: "",
      codeSession: "",
      sourceUrl: "",
      enquirySource: "",
      VerifyCode: "",
      enquiryType: 0
    },
    visible:false,
   relaList:[],

    imgList:[],
    xgOption: {
      slidesPerView: 1,
      spaceBetween: 30,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    },
    tou:0,
    confirmLoading:false
  }
  },
  created() {
    this.getdata()
  },
  methods:{

    getdata(){
      this.$axios.get('api/shopDetail',{params:{id:this.$route.query.id}}).then(res=>{
       this.shopDetail = res.data
         this.tou = res.data.catId[0]
        this.imgList = res.data.shopImgList.slice(1,res.data.shopImgList.length)


        for(var i = 0 ;i<this.shopDetail.shopCustomProp.length;i++){
            if(this.shopDetail.shopCustomProp[i].name=='text'){
              this.shopDetailText = this.shopDetail.shopCustomProp[i].value
            }
            if (this.shopDetail.shopCustomProp[i].name=='title'){
              this.shopDetailTitle = this.shopDetail.shopCustomProp[i].value
            }
          }
      })
      this.$axios.get('/api/someShops',{params:{id:this.$route.query.id}}).then(res=>{
        this.relaList = res.data
        console.log(this.relaList);
      })


    },
    showModal() {
      this.visible = true;
      this.$axios.get('/api/getEnquiryCode').then(res=>{
        this.yanzheng = res.data.data.imgData
        console.log(this.yanzheng,'123')
        this.PushxundanList.codeSession = res.data.data.session
        console.log(res.data)
      })

    },
    onMounted() {

      if (this.picIndex<4){
        this.picIndex=this.picIndex+1
      }
      if(this.picIndex==4){
        this.picIndex = 0
      }
// console.log(this.picIndex)
    },
    handleOk(e) {
      console.log(e)
      const is=this.formCheck(this.PushxundanList,1)
      if(is.is_ok==true){
        if(this.confirmLoading==false){
          this.ModalText = 'The modal will be closed after two seconds';
          this.confirmLoading = true;
          this.PushxundanList.shopID = this.$route.params.id
          this.PushxundanList.contactName = this.PushxundanList.name
          this.PushxundanList.contactEMail = this.PushxundanList.email
          this.$axios.post('/api/pushEnquiry',this.PushxundanList).then(res=>{
            this.confirmLoading=false
            if(res.data.code==200){
              this.$message.success('Successed!');
            }else{
              this.$message.error('Failed!');
            }
          })
        }
      }else if(is.is_empty==true){
        // this.loading=false
        // this.$message.warning('The form can\'t be empty!');
        this.$message({message: 'The form can\'t be empty!',type: 'warning',offset:430,})
      }else{
        this.$message({message: 'Failed!',type: 'error',offset:430,})
      }
    },
    handleCancel() {
      // console.log(e)
      // console.log('Clicked cancel button');
      this.visible = false;
    },
    changeyanzheng(){
      this.$axios.get('/api/getEnquiryCode').then(res=>{
        this.yanzheng = res.data.data.imgData
      })
    },
  }
}
</script>

<style scoped>
@import url("../assets/css/st.css");
</style>
