<template>
<div  class="news">
  <div  class="product-banner news-banner">
    <div class="product-banner-inner">
      <div class="product-banner-inner-left wow animate__animated animate__bounceInLeft">
        NEWS
      </div>
      <div class="product-banner-inner-right wow animate__animated animate__bounceInRight">
        Our Latest Insights
      </div>
    </div>
  </div>
  <div class="product-line">
    <div class="product-line-inner">
      <div>
        <router-link to="/" style="color:white;">
          Home
        </router-link>
      </div>
      <div>/</div>
      <div>News</div>
    </div>
  </div>



<!--  NEWS-CONTENT-->
  <div class="news-content">
    <div v-if="showWhat==2" class="news-content-left"  >
      <p class="news-content-left-title">{{tolTotal[newsNum-1]}}
      </p>
      <div class="news-de-item">
        <img :src="'http://cdn.fish-cloud.com/'+newD.articleImg" alt="">
        <p class="news-de-item-title">{{ newD.name }}</p>
        <p class="news-de-item-text" v-html=" newD.articleContent">


        </p>
        <div class="prod-share">
          <div>
          <p class="prod-share-title">Share To: </p></div>
          <div class="bottom-shemei-item news-share">
            <a href="https://www.facebook.com/">
            <img src="../../public/img/facebook.png" alt="">
            </a>
          </div>
          <div class="bottom-shemei-item news-share">
            <a href="https://twitter.com/">
            <img src="../../public/img/niao.png" alt="">
            </a>
          </div>
          <div class="bottom-shemei-item news-share">
            <a href="http://instagram.com/">
            <img src="../../public/img/ins.png" alt="">
            </a>
          </div>

        </div>
      </div>

    </div>
  </div>
  <Bottom></Bottom>
</div>
</template>

<script>
import {WOW} from 'wowjs'
import Bottom from "@/components/Bottom";
export default {
  name: "News",
  components:{
    Bottom
  },
  data(){
    return{
      tolTotal:['COMPANY NEWS','INDUSTRIAL NEWS'],
      showWhat:1,
      newD:{},
      newsNum:1,
      newsInnercanshu:{
        pageIndex: 0,
        pageSize: 3,
        totalCount: 0,
        catId:'',
        datas: [
          {
            id: 0,
            articleUrl: "string",
            articleImg: "string",
            articleContent: "string",
            articleAbstract: "string",
            releaseDate: "2021-02-01T03:46:32.146Z",
            userId: 0,
            name: "string",
            seoTitle: "string",
            seoKeyWords: "string",
            seoDescription: "string",
            someItemsId: [
              0
            ],
            articleAuthor: "string",
            articleForm: "string",
            articleWebUrl: "string",
            browseCount: 0
          }
        ],


      },
      newList:[],
      catInfo:[],
      newList1:[],
      newList2:[]
    }
  },
  mounted() {
    new WOW().init()
  },
  created() {
    this.getdata();
  },
  methods:{
    changeNews(e,f){
      this.showWhat=1
      this.newsNum = e,
        this.newsInnercanshu.catId = f
      this.$axios.get('http://localhost:8080/static/test.json', {params:this.newsInnercanshu}).then(res=>{
        this.newList=res.data.datas
      })

    },
    getNews(e){
      this.showWhat=2
      this.$axios.get('http://localhost:8080/static/test.json',{params:{id:e}}).then(res=>{

        this.newD = res.data
        console.log(this.newD)
      })
    },
    getdata(){

        this.$axios.get('http://localhost:8080/static/test.json',{params:{catType:1}}).then(res=>{
          this.catInfo = res.data
          console.log(this.catInfo[0].id,'123')

        }),

        

this.newsInnercanshu.catId = 308
      this.$axios.get('http://localhost:8080/static/test.json', {params:this.newsInnercanshu}).then(res=>{
        this.newList=res.data.datas
        this.newList1 = res.data.datas

      })
      this.newsInnercanshu.catId = 309
      this.$axios.get('http://localhost:8080/static/test.json', {params:this.newsInnercanshu}).then(res=>{
        // this.newList=res.data.datas
        this.newList2 = res.data.datas
      })
    },

    fasong(){
      this.$http.get('/msg1').then(function (res) {
        console.log(res)
      })
    }
  }

}
</script>

<style scoped>
@import url("../assets/css/st.css");
</style>
