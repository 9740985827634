<template>
<div></div>
</template>

<script>
export default {
name: "blank",
  created() {
    this.$router.push({path:'productD',query:{id:this.$route.query.id,catid:this.$route.query.catid}})
  }
}
</script>

<style scoped>

</style>