<template>
  <div class="contact">
    <div  class="product-banner contact-banner">

      <div class="product-banner-inner">
        <div class="product-banner-inner-left wow animate__animated animate__bounceInLeft">
          CONTACT US
        </div>
        <div class="product-banner-inner-right wow animate__animated animate__bounceInRight">
          We Respond To Every Request
        </div>
      </div>
    </div>
    <!--line-->
    <div class="product-line">
      <div class="product-line-inner">
        <a href="/" style="color: white">
        <div>
          Home
        </div>
        </a>
        <div>/</div>
        <div>Contact</div>
      </div>
    </div>

<!--    -->
    <div class="contact-content">
      <div class="contact-content-left">
        <p class="contact-content-left-title">
          CONTACT US
        </p>

        <div class="contact-content-area">
            <div class="contact-content-area-item ">
                Email:<br>
              <input type="text"  v-model="contactForm.email">
            </div>
          <div class="contact-content-area-item">
           Name:<br>
            <input type="text" v-model="contactForm.name">
          </div>
          <div class="contact-content-area-item" style="margin-top: 30px">
            Message:<br><br>
            <textarea name="" id="" cols="30" rows="10"  v-model="contactForm.message"></textarea>
          </div>
        </div>

   <div class="contact-content-area-anniu home-middle-contact-inner-right-anniu" @click="postContact()" v-loading="loading">
     <a >
     Send
     </a>
   </div>

        <div class="contact-content-message">
          <p class="contact-content-message-email">
            <span style="font-family: Ubuntu-b;font-size: 24px;color: #24272A">Email:</span>
            tina@shenjiti.com
          </p>
          <p class="contact-content-message-email">
            <span style="font-family: Ubuntu-b;font-size: 24px;color: #24272A">Tel:</span>
           +86 13362222908
          </p>
          <p class="contact-content-message-email">
            <span style="font-family: Ubuntu-b;font-size: 24px;color: #24272A">Address:</span>
            Xiaoshu Industrial Zone, Meixi Town, Anji County, Huzhou, Zhejiang, China
          </p>
        </div>
      </div>
      <div class="contact-content-right">
        <img src="../../public/img/contac-pic1.png" alt="">
      </div>
    </div>
<Bottom></Bottom>
  </div>
</template>

<script>
import WOW from 'wowjs'
import Bottom from "@/components/Bottom";
export default {
name: "Contact",
  components:{
  Bottom
  },
  data(){
    return{
      loading:false,
      contactForm:{
        name: "",
        jobTitle: "",
        companyName: "",
        email: "",
        phoneNumber: "",
        message: "",
        loading:false,
      }
    }
  } ,
  mounted() {
  new WOW().init()
  },
  methods:{
    postContact(){
      const is=this.formCheck(this.contactForm,2)
      if(is.is_ok==true){
        if(this.loading==false){
          this.loading=true
          this.$axios.post('/api/pushContact',this.contactForm).then(res=>{
            this.loading=false
            if(res.data.code==200){
              this.$message.success('Successed!');
            }else{
              this.$message.error('Failed!');
            }
          })
        }
      }else if(is.is_empty==true){
        // this.loading=false
        // this.$message.warning('The form can\'t be empty!');
        this.$message({message: 'The form can\'t be empty!',type: 'warning',offset:430,})
      }else{
        this.$message({message: 'Failed!',type: 'error',offset:430,})
      }
    }
  }
}
</script>

<style scoped>
@import url("../assets/css/st.css");
</style>
